import React, { useEffect, useState } from 'react';
import { Dialog } from '../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import PilotageApiRepository from '../../../repositories/api/PilotageApiRepository';
import Overlay from '../../../components/layout/overlay/Overlay';

export default function PilotageVoyageDialog({ onClose, pilotageId }) {

    const [voyageLocations, setVoyageLocations] = useState(null);
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        initializeAsync()
    }, []);

    async function initializeAsync() {
        setIsBusy(true);
        var response = await PilotageApiRepository.getPilotageVoyageAsync(pilotageId);

        if (response.ok) {
            var voyageLocations = await response.json();
            setVoyageLocations(voyageLocations);
        }
        setIsBusy(false);
    }

    return (
        <>
            <Dialog
                properties={{
                    ...DialogProperties,
                    title: "Tidligere havner",
                    onClose: onClose,
                    className: "dialog-title",
                }}>

                {voyageLocations ?
                    voyageLocations.map((voyageLocation, index) =>
                        <div key={index} className="dialog-row">
                            {voyageLocation}
                        </div>
                    )
                    :
                    <div>Ingen tidligere havner er tilgjengelig</div>
                }
            </Dialog>
            <Overlay isBusy={isBusy} />
        </>
    )
}
